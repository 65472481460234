import React from "react";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";

import { Icons, IconsV2 } from "@/public/images/all";
import Text from "@/components/v2/atoms/Text/Text";
import Button from "@/components/v2/molecules/Button/Button";
import useCustomerAccount from "../../hooks/useCustomerAccount";
import { getSourceTypeName } from "@/components/v2/contexts/OrderContext/helpers";
import { formatMoney } from "@/static/lib/util";
import { useOrderContextNew } from "../../contexts/OrderContextNew/OrderContextNew";
import { OrderOrigin, SourceType } from "@/generated/requests/pos";
import QuickOrderProductImage from "./QuickOrderProductImage";
import { useGetSourceBusinessHours } from "../../operations/queries";
import dayjs from "dayjs";
import { useState } from "react";
import classNames from "classnames";
import { firebaseTrackOneTapStart } from "@/static/lib/firebase";

export default function QuickOrder() {
  const { customerInfo: customer } = useCustomerAccount();
  const { upsertOrderItems, setTimeSlot, setDeliveryAddress, clearOrder } = useOrderContextNew();
  const [isLoading, setIsLoading] = useState(false);

  const quickOrderDeets = customer?.quickOrderDeets;
  const defaultAddressId = customer?.defaultAddressId;

  const { data: businessHoursData, loading: businessHoursLoading } = useGetSourceBusinessHours({
    storeId: quickOrderDeets?.store?.storeId,
    type: quickOrderDeets?.source?.type,
    selectedDate: dayjs()?.format("YYYY-MM-DD"),
    shouldSkip: !!quickOrderDeets?.pickupOrDeliveryTime,
  });
  const availableTimeSlots = businessHoursData?.public?.sourceForStore?.businessHoursForDay?.pickupTimeSlots;
  const { data: nextDayBusinessHoursData } = useGetSourceBusinessHours({
    storeId: quickOrderDeets?.store?.storeId,
    type: quickOrderDeets?.source?.type,
    selectedDate: dayjs()?.add(1, "day")?.format("YYYY-MM-DD"),
    // only call this query if there are no timeslots avaliable for today, and current day business hours query has completed
    shouldSkip: !!availableTimeSlots?.length || businessHoursLoading || !!quickOrderDeets?.pickupOrDeliveryTime,
  });
  const nextDayAvailableTimeSlots =
    nextDayBusinessHoursData?.public?.sourceForStore?.businessHoursForDay?.pickupTimeSlots;
  // if there are no timeslots avaliable for today, need to get the next day
  const orderTimeSlot =
    quickOrderDeets?.pickupOrDeliveryTime ||
    availableTimeSlots?.[0]?.startTimestamp ||
    nextDayAvailableTimeSlots?.[0]?.startTimestamp;

  const router = useRouter();
  const { t } = useTranslation();

  const handleQuickOrderClick = async () => {
    firebaseTrackOneTapStart();
    setIsLoading(true);
    try {
      await clearOrder();

      const orderData = {
        origin: OrderOrigin.Web,
        sourceId: quickOrderDeets?.source?.sourceId,
        source: {
          type: quickOrderDeets?.source?.type,
        },
      };

      const modifiers = quickOrderDeets?.modifiers?.map((modifier) => ({
        ...modifier,
        options: modifier.options.flatMap(({ quantity, ...option }) => Array(quantity).fill(option)),
      }));

      if (quickOrderDeets?.source?.type === SourceType.Delivery) {
        await setDeliveryAddress(
          quickOrderDeets?.deliveryAddress ||
            customer?.addressList?.addresses?.find((address) => address?.addressId === defaultAddressId) ||
            customer?.addressList?.addresses?.[0],
        );
      }

      setTimeSlot(orderTimeSlot);
      await upsertOrderItems({
        order: orderData,
        items: [{ productId: quickOrderDeets?.product?.productId, modifiers, quantity: 1 }],
        timeSlot: orderTimeSlot,
        sourceId: quickOrderDeets?.source?.sourceId,
        sourceType: quickOrderDeets?.source?.type,
      });

      await router.push(
        `/order/${quickOrderDeets?.source?.type.toLowerCase()}/${quickOrderDeets?.store?.detail?.slug}/checkout?isQuickOrder=true`,
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-stretch p-[15px] mx-[15px] my-[20px] bg-primary-light rounded-xl gap-y-[10px]">
      <div className="flex flex-col">
        <Text variant="display3">{t("home:one_tap_order")}</Text>
        <Text variant="body1">{t("home:one_tap_order_description")}</Text>
      </div>
      <div className="flex justify-between gap-x-[20px]">
        <div className="w-1/2">
          <QuickOrderProductImage quickOrderDeets={quickOrderDeets} />
        </div>
        <div className="flex flex-col justify-center gap-y-[10px] w-1/2">
          <div className="flex gap-x-[5px]">
            <Image src={IconsV2.noteList} alt="" height={20} width={20} />
            <Text variant="smallHeader">{quickOrderDeets?.product?.name}</Text>
          </div>
          <div className="flex gap-x-[5px]">
            <Image src={IconsV2.alarmClockBlack} alt="" height={20} width={20} />
            <Text variant="smallHeader">
              {t(`order:${getSourceTypeName(quickOrderDeets?.source?.type)}`)}:{" "}
              <span className="font-normal">ASAP</span>
            </Text>
          </div>
          <div className="flex gap-x-[5px]">
            <Image src={Icons.store2Filled} alt="" height={20} width={20} />
            <Text variant="smallHeader">{quickOrderDeets?.store?.detail?.name}</Text>
          </div>
        </div>
      </div>
      <Button
        className="m-auto w-full z-20"
        onClick={handleQuickOrderClick}
        disabled={isLoading || (!quickOrderDeets?.pickupOrDeliveryTime && businessHoursLoading)}
      >
        <div className="flex justify-between w-full">
          <Text>{t("home:order_now")}</Text>
          <div className="flex gap-[6px]">
            <Text className={classNames({ "line-through opacity-70": quickOrderDeets?.priceWithDiscounts?.amount })}>
              {formatMoney(quickOrderDeets?.price?.amount)}
            </Text>
            {quickOrderDeets?.priceWithDiscounts?.amount && (
              <Text>{formatMoney(quickOrderDeets?.priceWithDiscounts?.amount)}</Text>
            )}
          </div>
        </div>
      </Button>
    </div>
  );
}
