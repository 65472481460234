import type React from "react";
import Image from "next/image";
import { ImagesV2 } from "@/public/images/all";
import CookieDynamicProductImage from "../../molecules/CookieDynamicProductImage/CookieDynamicProductImage";
import { ProductModifierSpecialSubtype } from "@/generated/requests/services";

export default function QuickOrderProductImage({ quickOrderDeets }) {
  const selectedFlavors = quickOrderDeets?.modifiers?.[0]?.options.flatMap((option) =>
    Array.from({ length: option.quantity }, () => ({ ...option, quantity: 1 })),
  );
  const isLargeProduct = quickOrderDeets?.product?.modifiers?.[0]?.specialSubtypes?.includes?.(
    ProductModifierSpecialSubtype.Large,
  );

  return (
    <div className="w-full relative -left-[15px] ">
      <Image src={ImagesV2.quickOrderImageBackground} alt="" />
      <CookieDynamicProductImage
        numFlavors={quickOrderDeets?.modifiers?.[0]?.options?.reduce((acc, option) => acc + option.quantity, 0)}
        selectedFlavors={selectedFlavors}
        isLargeProduct={isLargeProduct}
        className="absolute top-0 left-0 scale-125"
      />
    </div>
  );
}
