import type { RefObject } from "react";

import { useEffect, useMemo, useState } from "react";

export function useOnScreen(ref: RefObject<HTMLElement>) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(() => {
    if (typeof window !== "undefined") {
      return new IntersectionObserver(([entry]) => setIntersecting(entry?.isIntersecting));
    }
  }, [ref]);

  useEffect(() => {
    if (ref.current) {
      observer?.observe(ref?.current);
    }
    return () => observer?.disconnect();
  }, []);

  return isIntersecting;
}
