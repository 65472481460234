import { useState, useEffect } from "react";
import { useUserTrackingConsentContext } from "../contexts/UserTrackingConsentContext/UserTrackingConsentContext";
import { useCustomerContext } from "../contexts/CustomerContext/CustomerContext";
const TOGGLE_ONE_TAP_ORDER_WEB = "toggle_one_tap_order_web";

interface FeatureFlagType {
  enabled: boolean;
  id: string;
  properties: any;
}

export default function useBrazeFeatureFlags() {
  const { consent } = useUserTrackingConsentContext();
  const { customer } = useCustomerContext();
  const [toggleOneTapOrderWeb, setToggleOneTapOrderWeb] = useState<FeatureFlagType>(undefined);

  // braze gets loaded from google tag manager, and then needs to be initialized. hence the polling.
  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    // Function to execute once braze is available
    const initBraze = () => {
      // @ts-ignore
      const braze = window.braze;
      let attempts = 0;
      const maxAttempts = 10; // 1 second maximum wait
      const checkBrazeGetFeatureFlag = setInterval(() => {
        attempts++;
        const oneTapOrderFeatureFlag = braze?.getFeatureFlag(TOGGLE_ONE_TAP_ORDER_WEB);
        // if its undefined, it means braze is not initialized yet. if its null, it means the feature flag is not enabled.
        if (typeof oneTapOrderFeatureFlag !== "undefined") {
          clearInterval(checkBrazeGetFeatureFlag);
          setToggleOneTapOrderWeb(oneTapOrderFeatureFlag);
          braze?.logFeatureFlagImpression(TOGGLE_ONE_TAP_ORDER_WEB);
        } else if (attempts >= maxAttempts) {
          clearInterval(checkBrazeGetFeatureFlag);
          console.warn("Braze not loaded after max attempts");
        }
      }, 100);
    };

    // use polling to get the braze object on window (it is initialized from google tag manager) (checks every 100ms)
    let attempts = 0;
    const maxAttempts = 10; // 1 second maximum wait
    const checkBrazeInterval = setInterval(() => {
      attempts++;
      // @ts-ignore
      if (window.braze) {
        clearInterval(checkBrazeInterval);
        initBraze();
      } else if (attempts >= maxAttempts) {
        clearInterval(checkBrazeInterval);
        console.warn("Braze not loaded after max attempts");
      }
    }, 100);

    // Cleanup interval on unmount
    return () => clearInterval(checkBrazeInterval);
  }, [consent?.marketing, customer?.userId]);

  return {
    toggleOneTapOrderWeb,
  };
}
